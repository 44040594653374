import route, {
  Config,
  RouteParam,
  RouteParamsWithQueryOverload,
} from 'ziggy-js';
import { Ziggy } from '~/routes';

export default defineNuxtPlugin((nuxtApp) => {
  const ziggyRouter = (
    name: string,
    routeParam?: RouteParamsWithQueryOverload | RouteParam,
    absolute?: boolean
  ): string => {
    return route(name, routeParam, absolute, Ziggy as Config);
  };

  nuxtApp.provide('ziggy', ziggyRouter);
});
