import { useModalerStore, UhModalProps } from '~/stores/modaler';

export default defineNuxtPlugin(() => {
  function showModal<UhModalComponent extends object>(
    component: UhModalComponent,
    props: UhModalProps,
    duration: number = 0
  ) {
    const modaler = useModalerStore();

    return modaler.showModal({
      component: markRaw(component),
      props,
      duration,
    });
  }

  function closeModal(id: number): boolean {
    const modaler = useModalerStore();
    return modaler.removeModal(id);
  }

  return {
    provide: {
      showModal,
      closeModal,
    },
  };
});
