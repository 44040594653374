<script setup lang="ts">
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';
import BoostOrderItem from './BoostOrderItem.vue';

import propertiesApi from '~/modules/properties/services/propertiesApi';
import { useCheckoutStore } from '~/modules/checkout/stores';
import { OrderItem } from '~/modules/checkout/types';

type BoostPriceData = Domain.Properties.DataObjects.BoostPriceData;

const loading = ref<boolean>(true);
const boostPricing = ref<BoostPriceData[]>();
const selectedItem = ref<number>();

const checkoutStore = useCheckoutStore();

onBeforeMount(async () => {
  const { data } = await propertiesApi.getBoostPricing();

  if (!data.value) {
    throw new Error('Unable to fetch boost pricing data.');
  }

  boostPricing.value = data.value;
  loading.value = false;
});

function selectBoostItem(item: BoostPriceData): void {
  selectedItem.value = item.id;

  const orderItem: OrderItem<BoostPriceData> = {
    name: `${item.quantity} boosts`,
    amount: item.price * 100, // Convert into pence
    data: item,
  };

  checkoutStore.items = [orderItem];
}
</script>

<template>
  <div class="grid grid-cols-1 gap-4 xs:grid-cols-2">
    <template v-if="loading">
      <Skeletor
        v-for="skeleton in 6"
        :key="skeleton"
        :height="74"
        width="100%"
        class="rounded-md"
      />
    </template>
    <template v-else>
      <BoostOrderItem
        v-for="item in boostPricing"
        :id="item.id"
        :key="item.id"
        :quantity="item.quantity ?? 0"
        :price="item.price"
        :selected="item.id === selectedItem"
        @click="selectBoostItem(item)"
      ></BoostOrderItem>
    </template>
  </div>
</template>
