import { helpers } from '@vuelidate/validators';
import { Delta } from '@vueup/vue-quill';
import dayjs, { ConfigType } from 'dayjs';

export const mustBeTrue = (value: unknown): boolean => value === true;

export const postcode = helpers.regex(
  /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/,
);

export const humanName = helpers.regex(
  /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/g,
);

export const ukMobileNumber = helpers.regex(
  /^((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}$/,
);

export const notBeQuillEmptyState = (
  value: Delta | string | null | undefined,
): boolean => value !== '<p><br></p>';

export const afterDate = (date: ConfigType) =>
  helpers.withParams(
    { type: 'after', value: date },
    (value: ConfigType) => dayjs(value).isAfter(date),
  );

export const afterStartDate = (startDate: ConfigType) =>
  helpers.withMessage(
    'The end date cannot be before the start date',
    afterDate(startDate),
  );

export const notZero = (index: ConfigType) => index !== 0;