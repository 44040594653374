<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { cva, type VariantProps } from 'class-variance-authority';
import { Spinner } from 'flowbite-vue';
import NuxtLink from '#app/components/nuxt-link';
import type { RouteLocationRaw } from '#vue-router';

const slots = useSlots();

const button = cva(
  'inline-block gap-2 border text-center font-medium focus:outline-none focus:ring-4',
  {
    variants: {
      color: {
        default:
          'border-transparent bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-300',
        blue: 'bg-blue-700 text-white hover:bg-blue-800 focus:ring-blue-300',
        alternative:
          'border border-gray-300 bg-white text-gray-900 duration-75 hover:border-gray-500 focus:z-10 focus:ring-4 focus:ring-gray-200',
        dark: 'bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-300',
        light:
          'border border-gray-300 bg-white text-gray-900 focus:ring-4 focus:ring-gray-200',
        green:
          'dark:hover:bg-green-70 bg-green-700 text-white hover:bg-green-800 focus:ring-4 focus:ring-green-300',
        red: 'border-transparent bg-red-700 text-white hover:bg-red-800 focus:ring-4 focus:ring-red-300',
        yellow:
          'border-transparent bg-yellow-500 text-white hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300',
        link: 'font-medium text-blue-600 hover:underline',
        transparent: '',
        gray: 'bg-gray-100 text-gray-800 hover:bg-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-200',
        orange:
          'border-orange-500 bg-orange-500 text-white transition-colors duration-75 hover:border-orange-600 hover:bg-orange-600 focus:ring-4 focus:ring-orange-200',
      },
      size: {
        xs: 'px-2 py-1 text-xs',
        sm: 'px-3 py-1.5 text-sm',
        md: 'min-h-[42px] px-4 py-2.5 text-sm',
        lg: 'px-5 py-2.5 text-base',
        xl: 'px-6 py-3 text-base',
        // Ratios
        '1:1': 'p-4',
      },
      square: {
        true: '',
        false: 'rounded-lg',
      },
      block: {
        true: 'inline-block w-full justify-center',
      },
      nowrap: {
        true: 'whitespace-nowrap',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-50',
        false: 'transition-all duration-75 ease-in group-hover:bg-opacity-0',
      },
      loading: {
        true: 'cursor-not-allowed justify-center',
      },
      inState: {
        true: 'inline-flex items-center',
      },
    },
    compoundVariants: [
      {
        block: true,
        inState: true,
        class: 'inline-flex w-full items-center justify-center',
      },
      {
        size: '1:1',
        block: false,
        class: 'inline-flex',
      },
      {
        size: '1:1',
        color: 'transparent',
        class: 'focus:ring-0',
      },
    ],
  },
);

const inState = computed(
  () => !!slots.prefix || !!slots.suffix || props.loading,
);

const buttonClasses = computed(() =>
  button({
    color: props.color,
    size: props.size,
    square: props.square,
    block: props.block,
    disabled: props.disabled,
    inState: inState.value,
    loading: props.loading,
    nowrap: props.nowrap,
  }),
);

type ButtonClassProps = VariantProps<typeof button>;

type ButtonProps = {
  to?: RouteLocationRaw;
  href?: RouteLocationRaw;
  type?: string;
  color?: ButtonClassProps['color'];
  size?: ButtonClassProps['size'];
  disabled?: boolean;
  loading?: boolean;
  square?: boolean;
  block?: boolean;
  nowrap?: boolean;
};

const props = withDefaults(defineProps<ButtonProps>(), {
  to: undefined,
  href: undefined,
  type: undefined,
  color: 'default',
  size: 'md',
  square: false,
  block: false,
  disabled: false,
  loading: false,
  nowrap: false,
});

const isNuxtLink = computed(() => !!props.to || !!props.href);

const type = computed(() =>
  isNuxtLink.value ? undefined : props.type ?? 'button',
);

const shouldBeDisabled = computed(() => props.disabled || props.loading);
</script>

<template>
  <Component
    :is="isNuxtLink ? NuxtLink : 'button'"
    :disabled="shouldBeDisabled"
    :loading="props.loading"
    :class="buttonClasses"
    :to="shouldBeDisabled ? undefined : props.to"
    :href="shouldBeDisabled ? undefined : props.href"
    :type="type"
  >
    <div v-if="slots.prefix && !props.loading">
      <slot name="prefix" />
    </div>

    <Spinner v-if="props.loading" size="5" />
    <slot v-else />

    <div v-if="slots.suffix && !props.loading">
      <slot name="suffix" />
    </div>
  </Component>
</template>
