
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isBetween from 'dayjs/plugin/isBetween'


dayjs.extend(advancedFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(customParseFormat)
dayjs.extend(isLeapYear)
dayjs.extend(isBetween)

dayjs.locale('en')
dayjs.locale('en-gb')
dayjs.tz.setDefault('Europe/London')



export default dayjs
