import { AsyncData } from 'nuxt/app';

export default {
  getBoostPricing(): AsyncData<Domain.Properties.DataObjects.BoostPriceData[], any> {
    return useApiFetch(useZiggy('agents.properties.boost.pricing'), {
      method: 'GET',
      watch: false,
    });
  },
};
