<script setup lang="ts">
import simplur from 'simplur';
import { computed, onBeforeMount, ref } from 'vue';
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

import PropertiesSelectTable from './PropertiesSelectTable.vue';
import { useCheckoutStore } from '~/modules/checkout/stores';
import { CheckoutType, FeaturePricesResponse } from '~/modules/checkout/types';

const checkoutStore = useCheckoutStore();

const loading = ref<boolean>(true);
const toaster = useToaster();

const selectedCountTitle = computed<string>(
  () => simplur`${checkoutStore.propertyIds.size} propert[y|ies] selected`
);

const skeletonHeight = computed<number>(
  () => checkoutStore.propertyIds.size * 80 + 56
);

async function getFeaturePrices(): Promise<void> {
  if (checkoutStore.prices.has(CheckoutType.FEATURE)) {
    loading.value = false;
    return;
  }

  const { data, error } = await useApiFetch<FeaturePricesResponse>(
    useZiggy('agents.properties.feature.pricing')
  );
  if (error.value) {
    console.error(error.value);
    loading.value = false;
    return;
  }

  checkoutStore.prices.set(CheckoutType.FEATURE, data.value?.data);
  loading.value = false;
}

onBeforeMount(async () => {
  // Navigate away if there aren't any selected property IDs set in the checkout store
  if (checkoutStore.propertyIds.size === 0) {
    await useRouter().push(checkoutStore.returningRoute);

    toaster.add(
      'warning',
      'Your checkout session expired. Please start again.'
    );
    return;
  }

  await getFeaturePrices();
});
</script>

<template>
  <div class="space-y-6">
    <div>
      <UhHeading lvl="5">Stand out from the crowd!</UhHeading>
      <p>
        By
        <UhLink
          to="https://www.unihomes.co.uk/letting-agent/resource-centre/How-To-Feature-A-Property"
          class="!text-base"
        >
          featuring a property</UhLink
        >, you increase the exposure of the property for a fixed period of time.
      </p>
    </div>

    <UhHR class="my-0" />

    <div class="w-full">
      <UhHeading lvl="5" class="mb-2">{{ selectedCountTitle }}</UhHeading>

      <Skeletor
        v-if="loading"
        :height="skeletonHeight"
        width="100%"
        class="rounded-md"
      />
      <PropertiesSelectTable v-if="!loading" />
    </div>
  </div>
</template>
