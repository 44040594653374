import UserbackPlugin from '@userback/vue';

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();

  const { status } = await useApiFetch('/userback', {
    watch: false,
  });

  let showUserBackWidget = status.value === 'success';

  if (config.public.userBack.show === 'true') {
    showUserBackWidget = true;
  }

  if (!showUserBackWidget) {
    return;
  }

  nuxtApp.vueApp.use(UserbackPlugin, {
    token: config.public.userBack.token,
  });
});
