import { RouteParam, RouteParamsWithQueryOverload } from 'ziggy-js';

// There is a nuxt useRoute composable already. So called useZiggy instead
export const useZiggy = (
  name: string,
  routeParam?: RouteParamsWithQueryOverload | RouteParam,
  absolute?: boolean
): string => {
  const { $ziggy } = useNuxtApp();

  // @ts-ignore
  return $ziggy(name, routeParam, absolute);
};

export type Ziggy = typeof useZiggy;
