import FeaturePropertyStep from '../components/CheckoutSteps/FeaturePropertyStep/FeaturePropertyStep.vue';
import { useCheckoutStore } from '../stores/index';
import {
  CheckoutSchema,
  FeatureOrderData,
  OrderItem,
  PurchaseFeaturesPayload,
} from './../types/index';
import checkoutApi from './checkoutApi';
import PropertyData = Domain.Properties.DataObjects.PropertyData;
import { usePropertiesStore } from '~/modules/properties/stores';

type InitData = number[] | Set<number>;

const featureCheckoutSchema: CheckoutSchema<InitData> = {
  title: 'Feature properties',
  successMessage: 'Congratulations. Your properties are featured!',
  component: FeaturePropertyStep,
  init: function (ids?: InitData): void {
    if (!ids) throw new Error('Invalid init feature checkout data provided.');
    if (Array.isArray(ids)) ids = new Set(ids);

    useCheckoutStore().propertyIds = ids;
  },
  submit: async function (): Promise<void> {
    const store = useCheckoutStore();
    const toaster = useToaster();

    const properties = store.items.map((item: OrderItem<FeatureOrderData>) => {
      const { id, featureDuration } = item.data as FeatureOrderData;
      return {
        id,
        feature_duration: featureDuration,
      };
    });

    const payload: PurchaseFeaturesPayload =
      createPurchasePayload<PurchaseFeaturesPayload>({
        properties,
      });

    const { data, error } = await checkoutApi.feature.purchase(payload);

    if (data.value?.status === 'success') {
      const featured = data.value?.properties as PropertyData[];

      featured.forEach((property: PropertyData) => {
        const propertiesStore = usePropertiesStore();

        const propertyIndex = propertiesStore.getPropertyIndex(property.id);
        if (propertyIndex === -1) return;

        // Replace the persisted property result data
        propertiesStore.tableResults[propertyIndex] = property;
      });

      await store.complete();
    }

    if (error.value?.data?.message) {
      toaster.add('danger', error.value.data.message, 3000);
    }

    if (error.value?.data.status === 'failed') {
      store.loading = false;
      throw new Error(error.value.data.message);
    }
  },
};

export default featureCheckoutSchema;
