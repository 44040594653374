<script setup lang="ts">
import { UhModalProps } from '~/stores/modaler';
import { ConfirmActionModalData } from '~/types/properties/modals';
import UhModal from '~/components/modals/UhModal.vue';
import UhButton from '~/components/general/uhButton/UhButton.vue';

const { $closeModal } = useNuxtApp();

const props = defineProps<UhModalProps<ConfirmActionModalData>>();

async function performAction(): Promise<void> {
  if (await props.data?.callback()) {
    close();
  }
}

function close(): void {
  $closeModal(props.modalId as number);
}
</script>

<template>
  <UhModal :open="true" :size="size" @close="close">
    <template #header>
      <span class="font-semibold text-gray-900 dark:text-gray-100">{{
        props.title ?? 'Confirm action'
      }}</span>
    </template>
    <template #body>
      <div class="text-gray-700 dark:text-gray-200">
        <p>
          <slot> {{ message ?? 'Are you sure?' }} </slot>
        </p>
      </div>
    </template>
    <template #footer>
      <UhButton color="light" @click="close">Cancel</UhButton>
      <UhButton @click="performAction">{{ props.confirmLabel ?? 'Confirm' }}</UhButton>
    </template>
  </UhModal>
</template>
