import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Config } from 'ziggy-js';
import { useEventBus, useLocalStorage } from '@vueuse/core';
import { useNotificationsStore } from '@notifications/stores';
import { SiteSettings } from './types';
import type { LivePropertiesCount } from '~/stores/mainStore/types';
import { globalKey } from '~/utils/events';
import { useAuth } from '#imports';

export const useMainStore = defineStore(
  'main',
  function () {
    useEventBus(globalKey);
    const notificationsStore = useNotificationsStore();
    const runtimeConfig = useRuntimeConfig();
    const apiBaseUrl = runtimeConfig.public.apiBaseUrl;
    const auth = useAuth();
    const page = ref<HTMLElement>();
    const ziggyConfig = useLocalStorage<Partial<Config>>('ZiggyConfig', {});
    const loadingAccountDetails = ref<boolean>(false);
    const boosts = ref<number>(0);
    const propertiesCount = ref<number>(0);
    const livePropertiesCount = ref<LivePropertiesCount>({
      live: 0,
      limit: 0,
      pending: false,
    });
    const enquiriesCount = ref<number>(0);
    const liveUnreadEnquiriesCount = ref<number>(0);
    const totalAccounts = ref<number>(0);

    const siteSettings = ref<SiteSettings>({
      enableLettingAgentPortalExcelImport: true,
      showAgentPortalBetaToggleSwitch: false,
      lowestBoostData: {
        quantity: 0,
        price: 0,
      },
    });

    // Getters
    const canUploadOrderForms = computed<boolean>(() => {
      if (!auth.user) return false;

      return auth.user.forms_upload_enabled;
    });

    const canCreateOrderForms = computed<boolean>(() => {
      if (!auth.user) return false;

      return auth.user.utilities_order_create_enabled;
    });

    const portfolioLink = computed<string>(() => {
      return `${apiBaseUrl}/student-accommodation?letting-agent=${auth.user.id}`;
    });

    // Actions
    function handleAccountLiveCountsUpdateEvent(
      event: Account.Events.LiveCountsUpdated,
    ) {
      livePropertiesCount.value = {
        live: event.livePropertiesCount,
        limit: event.livePropertiesLimit,
        pending: false,
      };

      liveUnreadEnquiriesCount.value = event.unreadEnquiriesCount;
      boosts.value = event.boostsCount;
    }

    function registerWebsocketEvents(userId: number): void {
      const { $echo } = useNuxtApp();
      // eslint-disable-next-line no-console
      console.log(
        '👂 Listening for Websocket Events on channel:',
        `private-agents-${userId}`,
      );

      $echo
        .private(`agents-${userId}`)
        .notification((notification) => {
          if (notification.type === 'notifications.enquiry.new') {
            notificationsStore.handleNewEnquiryNotification(notification);
          }
        })
        .listen(
          '.account.liveCounts.update',
          (event: Account.Events.LiveCountsUpdated) =>
            handleAccountLiveCountsUpdateEvent(event),
        );
    }

    async function getAccountDetails(): Promise<void> {
      loadingAccountDetails.value = true;

      const { data } =
        await useApiFetch<Domain.Account.Profile.DataObjects.AccountDetailsData>(
          useZiggy('agents.account.all'),
        );

      boosts.value = data.value?.boostsCount ?? 0;
      enquiriesCount.value = data.value?.enquiriesCount ?? 0;
      liveUnreadEnquiriesCount.value = data.value?.unreadEnquiriesCount ?? 0;
      propertiesCount.value = data.value?.propertiesCount ?? 0;
      livePropertiesCount.value = {
        live: data.value?.livePropertiesCount ?? 0,
        limit: data.value?.livePropertiesLimit ?? 0,
      };
      totalAccounts.value = data.value?.totalAccounts ?? 0;
      loadingAccountDetails.value = false;
    }

    async function getSiteSettings(): Promise<void> {
      const { data } = await useApiFetch<SiteSettings>('/site-settings');

      if (!data.value) return;

      siteSettings.value = data.value;
    }

    function addBoosts(count: number): void {
      boosts.value += count;
    }

    function scrollToTop(): void {
      if (page.value !== undefined) {
        page.value.scrollTo(0, 0);
      }
    }

    return {
      auth,
      boosts,
      canCreateOrderForms,
      canUploadOrderForms,
      loadingAccountDetails,
      propertiesCount,
      livePropertiesCount,
      enquiriesCount,
      liveUnreadEnquiriesCount,
      totalAccounts,
      page,
      portfolioLink,
      siteSettings,
      ziggyConfig,

      addBoosts,
      getAccountDetails,
      getSiteSettings,
      scrollToTop,
      registerWebsocketEvents,
    };
  },
  {
    persist: [
      {
        paths: [
          'livePropertiesCount',
          'propertiesCount',
        ],
        storage: localStorage,
      },
      {
        paths: [
          'boosts',
          'enquiriesCount',
          'canCreateOrderForms',
          'canUploadOrderForms',
          'liveUnreadEnquiriesCount',
          'portfolioLink',
          'totalAccounts',
        ],
        storage: sessionStorage,
      },
    ],
  },
);
