<script setup lang="ts" generic="T">
import { ref, computed, useSlots } from 'vue';
import type { SelectOptionsValue, SelectOption } from './types.d.ts';

const slots = useSlots();

interface SelectMenuProps<T> {
  id: string;
  name: string;
  label?: string;
  modelValue: SelectOptionsValue<T>;
  disabled?: boolean;
  placeholder?: string;
  options: SelectOption<T>[];
  align?: 'left' | 'right' | 'none';
  invalid?: boolean;
  errorMessage?: string;
  buttonClass?: string;
  buttonWrapperClass?: string;
  labelClass?: string;
  subLabelClass?: string;
}

const props = withDefaults(defineProps<SelectMenuProps<T>>(), {
  label: '',
  disabled: false,
  placeholder: undefined,
  align: 'none',
  invalid: false,
  errorMessage: '',
  buttonClass: '',
  buttonWrapperClass: '',
  labelClass: '',
  subLabelClass: '',
});

const emit = defineEmits(['update:modelValue']);

const open = ref<boolean>(false);
const menuRefs = ref<HTMLLIElement[]>([]);
const focusedOption = ref(0);
const selectMenuRef = ref<HTMLDivElement | null>(null);

onClickOutside(selectMenuRef, closeMenu);

const selectedName = computed<string | undefined>(() => {
  const option: SelectOption<T> | undefined = getSelectedOption(
    props.modelValue,
  );
  if (!option) return undefined;

  return option.selectedName ?? option.name;
});

function getSelectedOption(
  value: SelectOptionsValue<T>,
): SelectOption<T> | undefined {
  return props.options?.find((o: SelectOption<T>) => o.value === value);
}

const showPlaceholder = computed<boolean>(() => {
  return (props.modelValue == null && props.placeholder) as boolean;
});

function openMenu() {
  open.value = true;
}
function closeMenu() {
  open.value = false;
}
function toggleMenu() {
  open.value = !open.value;
}
function focusNextOption(event: Event): void {
  event.preventDefault();
  openMenu();
  focusedOption.value =
    focusedOption.value === props.options.length - 1
      ? 0
      : focusedOption.value + 1;
  menuRefs.value[focusedOption.value]?.scrollIntoView({
    block: 'nearest',
  });
}
function focusPrevOption(event: Event): void {
  event.preventDefault();
  openMenu();
  focusedOption.value =
    focusedOption.value === 0
      ? props.options.length - 1
      : focusedOption.value - 1;
  menuRefs.value[focusedOption.value]?.scrollIntoView({
    block: 'nearest',
  });
}
function selectOption(option: KeyboardEvent | SelectOption<T>): void {
  if (option instanceof KeyboardEvent) {
    option = props.options[focusedOption.value];
  }
  closeMenu();
  emit('update:modelValue', option.value);
}
</script>

<template>
  <div ref="selectMenuRef">
    <label
      v-if="props.label"
      :id="props.id"
      class="mb-2 block text-sm font-medium text-gray-900"
      :class="props.labelClass"
      @click="toggleMenu"
    >
      {{ label }}
    </label>
    <span
      v-if="slots.subLabel"
      class="mt text-sm text-gray-900"
      :class="props.subLabelClass"
    >
      <slot name="subLabel"></slot>
    </span>
    <div
      class="relative"
      :class="[
        props.align === 'left' ? 'float-left' : '',
        props.align === 'right' ? 'float-right' : '',
        props.buttonWrapperClass,
      ]"
    >
      <button
        type="button"
        :title="selectedName"
        class="relative flex min-h-[42px] w-full items-center rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-10 text-sm text-gray-900 hover:border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:hover:border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        :class="[
          {
            'gap-2.5': slots.icon,
            'cursor-not-allowed': props.disabled,
            'border-1 border-red-600': props.invalid,
          },
          buttonClass,
        ]"
        aria-haspopup="listbox"
        aria-expanded="true"
        :aria-labelledby="id"
        :disabled="props.disabled"
        @click="toggleMenu"
        @keydown.enter.prevent="selectOption"
        @keydown.arrow-down="focusNextOption"
        @keydown.arrow-up="focusPrevOption"
        @keydown.escape="closeMenu"
      >
        <span v-if="slots.icon">
          <slot name="icon"></slot>
        </span>
        <span
          class="block truncate text-left text-base"
          :class="{ 'text-gray-500 dark:text-gray-100': showPlaceholder }"
        >
          <template v-if="props.modelValue != null">
            {{ selectedName }}
          </template>

          <template v-if="showPlaceholder">
            {{ props.placeholder }}
          </template>
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <FontAwesomeIcon
            v-if="open"
            class="text-gray-500"
            :icon="['fas', 'chevron-up']"
          />
          <FontAwesomeIcon
            v-else
            class="text-gray-500"
            :icon="['fas', 'chevron-down']"
          />
        </span>
      </button>
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="ease-in-out duration-100"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-active-class="ease-in-out duration-300"
        leave-to-class="opacity-0"
      >
        <ul
          v-if="open"
          class="absolute z-10 mb-0 ml-0 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          :class="{
            'left-0': props.align === 'left',
            'right-0': props.align === 'right',
          }"
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          <li
            v-for="(option, index) in props.options"
            :id="'listbox-option-' + index"
            ref="menuRefs"
            :key="index"
            :tabindex="index === focusedOption ? 0 : -1"
            role="option"
            class="relative cursor-pointer select-none px-3 py-2.5 text-gray-700 hover:bg-blue-500 hover:text-white"
            :class="{
              'bg-blue-500 text-white': index === focusedOption,
            }"
            @mouseover="focusedOption = index"
            @click="selectOption(option)"
            @keydown.enter.prevent="selectOption"
            @keydown.arrow-down="focusNextOption"
            @keydown.arrow-up="focusPrevOption"
          >
            <span
              class="block truncate"
              :class="{
                'font-bold': option.value === props.modelValue,
                'font-normal': option.value !== props.modelValue,
              }"
            >
              {{ option.name }}
            </span>
          </li>
        </ul>
      </Transition>
    </div>
    <p
      v-if="slots.helper || props.invalid"
      class="mt-2 text-sm text-gray-500 dark:text-gray-400"
    >
      <span v-if="props.invalid" class="mt-2 text-red-600">
        {{ errorMessage }}
      </span>
      <slot name="helper"></slot>
    </p>
  </div>
</template>
