import { defineStore } from 'pinia';
import { ref } from 'vue';
import { PropertiesFilters, PropertiesQuery } from './../types/index';
import { useTableFetch } from '#imports';
import { TABLE_LOADING_DELAY } from '~/constants';
import { useHelpers } from '~/composables/useHelpers';
import { DATA_TABLE_CLEAR_SELECTED_IDS, globalKey } from '~/utils/events';
import PropertyData = Domain.Properties.DataObjects.PropertyData;

export type Properties = PropertyData[];

export const usePropertiesStore = defineStore(
  'properties',
  () => {
    const { updateRouteQueryParams } = useHelpers();

    const { emit: busEmit } = useEventBus(globalKey);

    const loading = ref<boolean>(false);
    const filtersOpen = ref<boolean>(false);
    const selectedIds = ref<Set<number>>(new Set());
    const tableResults = ref<Properties>([]);
    const tableMeta = ref<Record<string, string | number>>({ total: 0 });

    const query = ref<PropertiesQuery>({
      filter: {
        from: '',
        to: '',
        search: '',
        status: '',
        boosted: '',
        featured: '',
        bedroom: '',
        bathroom: '',
        selectedSorting: 'live',
      },
      page: 1,
      perPage: 30,
    });
    const filtersSnapshot = ref<PropertiesFilters>();

    const { records, meta, refresh, pending, fetchParams, execute } =
      useTableFetch<Properties>('properties', query, false);

    async function refreshTable(): Promise<void> {
      await refresh();
    }

    function clearFilters(): void {
      query.value.filter.from = meta.value.minPrice;
      query.value.filter.to = meta.value.maxPrice;
      query.value.filter.status = '';
      query.value.filter.boosted = '';
      query.value.filter.featured = '';
      query.value.filter.bedroom = '';
      query.value.filter.bathroom = '';
      refreshTable();
    }

    function updateTableResults(): void {
      loading.value = true;

      setTimeout(() => {
        tableResults.value = records.value;
        tableMeta.value = meta.value;
        query.value.page = 1;
        updateRouteQueryParams(unref(fetchParams));
        busEmit(DATA_TABLE_CLEAR_SELECTED_IDS, { table: 'properties' });
        loading.value = false;
      }, TABLE_LOADING_DELAY);
    }

    function getProperty(
      id: number,
      condition?: (propertyData: PropertyData) => boolean,
    ): PropertyData | null {
      const property: unknown = tableResults.value.find(
        (propertyData: PropertyData) => {
          if (condition && !condition(propertyData)) return false;
          return propertyData.id === id;
        },
      );
      return property as PropertyData | null;
    }

    function getPropertyIndex(id: number): number {
      return tableResults.value.findIndex(
        (property: PropertyData) => property.id === id,
      );
    }

    function getAllProperties(propertyIds: number[]): Properties {
      return propertyIds
        .map((propertyId: number): PropertyData | null =>
          getProperty(propertyId),
        )
        .filter(
          (property: PropertyData | null) => property != null,
        ) as Properties;
    }

    function removeResult(propertyIndex: number): void {
      tableResults.value.splice(propertyIndex, 1);
    }

    function updateResult(
      propertyId: number,
      applyUpdate: (data: PropertyData) => PropertyData,
      statusFilter?: string,
    ): boolean {
      const { status } = query.value.filter;

      const index = getPropertyIndex(propertyId);
      if (index === -1) return false;

      const property = applyUpdate(tableResults.value[index]);
      tableResults.value[index] = property;

      // Property status filter hasn't been specified by the user
      if (!status || status === '') {
        return true;
      }

      if (!statusFilter) return true;

      if (status === statusFilter) {
        // Remove the property from the table
        removeResult(index);
        return true;
      }

      // Individual property could not be updated in the results, return false to refresh the whole table
      return false;
    }

    // Only show the loading state when the filters modal is closed and the network is busy
    const isLoading = computed<boolean>(
      () => (!filtersOpen.value && pending.value) || loading.value,
    );

    watch(
      [
        () => query.value.page,
        () => query.value.perPage,
        () => query.value.filter.selectedSorting,
      ],
      () => {
        updateRouteQueryParams(unref(fetchParams));
      },
      { deep: true },
    );

    watch(records, () => {
      // Update table results when the filters modal is closed
      if (!filtersOpen.value) {
        tableResults.value = records.value;
        tableMeta.value = meta.value;
      }
    });

    watch(meta, () => {
      // Update table metadata when the filters modal is closed
      if (!filtersOpen.value) {
        tableMeta.value = meta.value;
      }

      if (!query.value.filter.from) {
        query.value.filter.from = meta.value.minPrice;
      }

      if (!query.value.filter.to) {
        query.value.filter.to = meta.value.maxPrice;
      }
    });

    watch(filtersOpen, () => {
      if (filtersOpen.value) {
        // Save a snapshot
        filtersSnapshot.value = Object.assign({}, query.value.filter);
        return;
      }

      // Manually update the table results when the filter modal is closed, if any filters have changed
      if (
        JSON.stringify(filtersSnapshot.value) !==
        JSON.stringify(query.value.filter)
      ) {
        updateTableResults();
      }
    });

    return {
      clearFilters,
      filtersOpen,
      getProperty,
      getPropertyIndex,
      getAllProperties,
      removeResult,
      updateResult,
      isLoading,
      meta,
      pending,
      query,
      records,
      refreshTable,
      selectedIds,
      tableMeta,
      tableResults,
      updateRouteQueryParams,
      updateTableResults,
      execute,
    };
  },
  {
    persist: {
      paths: [
        'tableMeta',
        'tableResults',
        'selectedIds',
        'meta',
        'records',
        'filtersOpen',
        'pending',
      ],
    },
  },
);
