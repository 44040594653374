// https://pipinghot.dev/tutorial/use-sentry-with-nuxt-3/
import {
  BrowserTracing,
  init,
  vueRouterInstrumentation,
  setContext,
  setUser,
  setTag,
  addBreadcrumb,
  captureException,
} from '@sentry/vue';
import { RuntimeConfig } from 'nuxt/schema';
import * as pkg from '~/package.json';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp, $router } = nuxtApp;

  const release = `${pkg.name}@${pkg.version}`;
  const config: RuntimeConfig = nuxtApp.$config;
  const dsn = config.public.sentry.dsn;
  const environment = config.public.sentry.environment;

  init({
    app: [vueApp],
    dsn,
    release,
    environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation($router),
        tracePropagationTargets: [
          'agents.unihomes.test',
          /^https:\/\/agents.unihomes.co.uk/,
          /^https:\/\/staging.agents.unihomes.co.uk/,
          /^\//,
        ],
      }),
    ],
    sampleRate: 1,
    tracesSampleRate: 1,
    logErrors: environment !== 'production',
    attachProps: true,
    attachStacktrace: true,
    tracingOptions: {
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    },
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        );
      }
      // Continue sending to Sentry
      return event;
    },
  });

  return {
    provide: {
      sentrySetContext: setContext,
      sentrySetUser: setUser,
      sentrySetTag: setTag,
      sentryAddBreadcrumb: addBreadcrumb,
      sentryCaptureException: captureException,
    },
  };
});
