import { defu } from 'defu';
import { type MaybeRefOrGetter, ref } from 'vue';
import { toRef, watchDebounced } from '@vueuse/core';
import { MetaData, PaginatedResponse, QueryParams } from './../../types/index';
import { useQueryBuilder } from './useQueryBuilder';
import { useApiFetch } from './useApiFetch';
import { useRoute } from '#app';

export function useTableFetch<DataT, MetaT = MetaData>(
  url: string,
  query: MaybeRefOrGetter<QueryParams>,
  immediate: boolean = true
) {
  const _query = toRef(query);
  const route = useRoute();
  const { parse, build } = useQueryBuilder();

  // merge default query params with route query params
  _query.value = defu(parse(route.query), _query.value);

  const fetchParams = ref(build(_query));

  watchDebounced(
    _query,
    () => {
      fetchParams.value = build(_query);
    },
    { deep: true, debounce: 500, maxWait: 2000 }
  );

  const { data, pending, error, refresh, execute } = useApiFetch<
    PaginatedResponse<DataT, MetaT>
  >(url, {
    watch: [fetchParams.value],
    query: fetchParams,
    pick: ['data', 'meta'],
    immediate,
  });

  const records = ref<DataT[]>([]);
  const meta = ref<MetaT & MetaData>({});

  watch(data, () => {
    if (data.value) {
      records.value = data.value.data;
      meta.value = data.value.meta;
    }
  });

  return { records, meta, data, pending, error, refresh, fetchParams, execute };
}
