import { default as test10CurlH8ceMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/pages/test.vue?macro=true";
import { default as indexAbJbRJiJD9Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/example/pages/index.vue?macro=true";
import { default as indexhiDJeiQcRYMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/index.vue?macro=true";
import { default as detailsIe6SggV4orMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/personal/details.vue?macro=true";
import { default as password00aIA1oSCAMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/personal/password.vue?macro=true";
import { default as details72VpVIq1ihMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/details.vue?macro=true";
import { default as enquiries6Y7Df3fjsPMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/enquiries.vue?macro=true";
import { default as utilitiesPr0qTqPYxXMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/utilities.vue?macro=true";
import { default as tenanciesL2BuF6MHCOMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/tenancies.vue?macro=true";
import { default as indexTaH4RmV0azMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/analytics/pages/index.vue?macro=true";
import { default as indexGqga7Xg8StMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/login/index.vue?macro=true";
import { default as index1PBbS2lpBiMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/forgot-password/index.vue?macro=true";
import { default as sentUxp3aivWHsMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/forgot-password/sent.vue?macro=true";
import { default as _91token_93Dvk5JzXvzTMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/reset-password/[token].vue?macro=true";
import { default as featuresW0c7tiKU3rMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/checkout/features.vue?macro=true";
import { default as paymentWR0Nr24VQfMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/checkout/payment.vue?macro=true";
import { default as indexzY1iJSUUxKMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/index.vue?macro=true";
import { default as successoE9mdCBkvBMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/success.vue?macro=true";
import { default as indexcJ04vicHBBMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/dashboard/pages/index.vue?macro=true";
import { default as index6MUbLrobDZMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/enquiries/pages/index.vue?macro=true";
import { default as indexMNp9c8M4lvMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/index.vue?macro=true";
import { default as indexJUDU7nXryPMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/import/index.vue?macro=true";
import { default as addresskJHU6monYvMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/address.vue?macro=true";
import { default as aboutG8TFU0cAyuMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/about.vue?macro=true";
import { default as listingqEihnCbNjxMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/listing.vue?macro=true";
import { default as mediamPFrfLxN6KMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/media.vue?macro=true";
import { default as propertyACIpkLrBkEMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property.vue?macro=true";
import { default as editR4hWBNoU2SMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/[uuid]/edit.vue?macro=true";
import { default as partnershipwKCkgEhelaMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/resources/pages/partnership.vue?macro=true";
import { default as marketing_45materialE4Jf3qiQ7vMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/resources/pages/marketing-material.vue?macro=true";
import { default as indexu8JwDpJf9iMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/tenancies/pages/index.vue?macro=true";
import { default as indexVjSOmGm7CEMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/index.vue?macro=true";
import { default as detailsb1nL6kkY1OMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/details.vue?macro=true";
import { default as tenantskcH6fm7HuDMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/tenants.vue?macro=true";
import { default as summaryiy1QMg2hOZMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/summary.vue?macro=true";
import { default as preview0ydTdfm08dMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/preview.vue?macro=true";
import { default as orderMjKqtjcrY3Meta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order.vue?macro=true";
import { default as confirmationZaZpVnZt6NMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/confirmation.vue?macro=true";
import { default as indexu0HvU7NplNMeta } from "/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/[uuid]/index.vue?macro=true";
export default [
  {
    name: test10CurlH8ceMeta?.name ?? "test",
    path: test10CurlH8ceMeta?.path ?? "/test",
    meta: test10CurlH8ceMeta || {},
    alias: test10CurlH8ceMeta?.alias || [],
    redirect: test10CurlH8ceMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/pages/test.vue").then(m => m.default || m)
  },
  {
    name: indexAbJbRJiJD9Meta?.name ?? "example",
    path: indexAbJbRJiJD9Meta?.path ?? "/example",
    meta: indexAbJbRJiJD9Meta || {},
    alias: indexAbJbRJiJD9Meta?.alias || [],
    redirect: indexAbJbRJiJD9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/example/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhiDJeiQcRYMeta?.name ?? "account-index",
    path: indexhiDJeiQcRYMeta?.path ?? "/account",
    meta: indexhiDJeiQcRYMeta || {},
    alias: indexhiDJeiQcRYMeta?.alias || [],
    redirect: indexhiDJeiQcRYMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/index.vue").then(m => m.default || m)
  },
  {
    name: detailsIe6SggV4orMeta?.name ?? "personal-details-settings",
    path: detailsIe6SggV4orMeta?.path ?? "/account/personal/details",
    meta: detailsIe6SggV4orMeta || {},
    alias: detailsIe6SggV4orMeta?.alias || [],
    redirect: detailsIe6SggV4orMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/personal/details.vue").then(m => m.default || m)
  },
  {
    name: password00aIA1oSCAMeta?.name ?? "personal-password-settings",
    path: password00aIA1oSCAMeta?.path ?? "/account/personal/password",
    meta: password00aIA1oSCAMeta || {},
    alias: password00aIA1oSCAMeta?.alias || [],
    redirect: password00aIA1oSCAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/personal/password.vue").then(m => m.default || m)
  },
  {
    name: details72VpVIq1ihMeta?.name ?? "company-details-settings",
    path: details72VpVIq1ihMeta?.path ?? "/account/company/details",
    meta: details72VpVIq1ihMeta || {},
    alias: details72VpVIq1ihMeta?.alias || [],
    redirect: details72VpVIq1ihMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/details.vue").then(m => m.default || m)
  },
  {
    name: enquiries6Y7Df3fjsPMeta?.name ?? "company-enquiries-settings",
    path: enquiries6Y7Df3fjsPMeta?.path ?? "/account/company/enquiries",
    meta: enquiries6Y7Df3fjsPMeta || {},
    alias: enquiries6Y7Df3fjsPMeta?.alias || [],
    redirect: enquiries6Y7Df3fjsPMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/enquiries.vue").then(m => m.default || m)
  },
  {
    name: utilitiesPr0qTqPYxXMeta?.name ?? "company-utilities-settings",
    path: utilitiesPr0qTqPYxXMeta?.path ?? "/account/company/utilities",
    meta: utilitiesPr0qTqPYxXMeta || {},
    alias: utilitiesPr0qTqPYxXMeta?.alias || [],
    redirect: utilitiesPr0qTqPYxXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/utilities.vue").then(m => m.default || m)
  },
  {
    name: tenanciesL2BuF6MHCOMeta?.name ?? "company-tenancies-settings",
    path: tenanciesL2BuF6MHCOMeta?.path ?? "/account/company/tenancies",
    meta: tenanciesL2BuF6MHCOMeta || {},
    alias: tenanciesL2BuF6MHCOMeta?.alias || [],
    redirect: tenanciesL2BuF6MHCOMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/account/pages/company/tenancies.vue").then(m => m.default || m)
  },
  {
    name: indexTaH4RmV0azMeta?.name ?? "analytics",
    path: indexTaH4RmV0azMeta?.path ?? "/analytics",
    meta: indexTaH4RmV0azMeta || {},
    alias: indexTaH4RmV0azMeta?.alias || [],
    redirect: indexTaH4RmV0azMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/analytics/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGqga7Xg8StMeta?.name ?? "login",
    path: indexGqga7Xg8StMeta?.path ?? "/login",
    meta: indexGqga7Xg8StMeta || {},
    alias: indexGqga7Xg8StMeta?.alias || [],
    redirect: indexGqga7Xg8StMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index1PBbS2lpBiMeta?.name ?? "forgot-password",
    path: index1PBbS2lpBiMeta?.path ?? "/forgot-password",
    meta: index1PBbS2lpBiMeta || {},
    alias: index1PBbS2lpBiMeta?.alias || [],
    redirect: index1PBbS2lpBiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: sentUxp3aivWHsMeta?.name ?? "forgot-password-sent",
    path: sentUxp3aivWHsMeta?.path ?? "/forgot-password/sent",
    meta: sentUxp3aivWHsMeta || {},
    alias: sentUxp3aivWHsMeta?.alias || [],
    redirect: sentUxp3aivWHsMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/forgot-password/sent.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Dvk5JzXvzTMeta?.name ?? "reset-password",
    path: _91token_93Dvk5JzXvzTMeta?.path ?? "/reset-password/:token",
    meta: _91token_93Dvk5JzXvzTMeta || {},
    alias: _91token_93Dvk5JzXvzTMeta?.alias || [],
    redirect: _91token_93Dvk5JzXvzTMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/auth/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexzY1iJSUUxKMeta?.name ?? "checkout",
    path: indexzY1iJSUUxKMeta?.path ?? "/checkout",
    children: [
  {
    name: featuresW0c7tiKU3rMeta?.name ?? "checkout-features",
    path: featuresW0c7tiKU3rMeta?.path ?? "features",
    meta: featuresW0c7tiKU3rMeta || {},
    alias: featuresW0c7tiKU3rMeta?.alias || [],
    redirect: featuresW0c7tiKU3rMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/checkout/features.vue").then(m => m.default || m)
  },
  {
    name: paymentWR0Nr24VQfMeta?.name ?? "checkout-payment",
    path: paymentWR0Nr24VQfMeta?.path ?? "payment",
    meta: paymentWR0Nr24VQfMeta || {},
    alias: paymentWR0Nr24VQfMeta?.alias || [],
    redirect: paymentWR0Nr24VQfMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/checkout/payment.vue").then(m => m.default || m)
  }
],
    meta: indexzY1iJSUUxKMeta || {},
    alias: indexzY1iJSUUxKMeta?.alias || [],
    redirect: indexzY1iJSUUxKMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/index.vue").then(m => m.default || m)
  },
  {
    name: successoE9mdCBkvBMeta?.name ?? "checkout-success",
    path: successoE9mdCBkvBMeta?.path ?? "/checkout/success",
    meta: successoE9mdCBkvBMeta || {},
    alias: successoE9mdCBkvBMeta?.alias || [],
    redirect: successoE9mdCBkvBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/checkout/pages/success.vue").then(m => m.default || m)
  },
  {
    name: indexcJ04vicHBBMeta?.name ?? "dashboard",
    path: indexcJ04vicHBBMeta?.path ?? "/",
    meta: indexcJ04vicHBBMeta || {},
    alias: indexcJ04vicHBBMeta?.alias || [],
    redirect: indexcJ04vicHBBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6MUbLrobDZMeta?.name ?? "enquiries",
    path: index6MUbLrobDZMeta?.path ?? "/enquiries",
    meta: index6MUbLrobDZMeta || {},
    alias: index6MUbLrobDZMeta?.alias || [],
    redirect: index6MUbLrobDZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/enquiries/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexMNp9c8M4lvMeta?.name ?? "properties-index",
    path: indexMNp9c8M4lvMeta?.path ?? "/properties",
    meta: indexMNp9c8M4lvMeta || {},
    alias: indexMNp9c8M4lvMeta?.alias || [],
    redirect: indexMNp9c8M4lvMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJUDU7nXryPMeta?.name ?? "properties-import",
    path: indexJUDU7nXryPMeta?.path ?? "/properties/import",
    meta: indexJUDU7nXryPMeta || {},
    alias: indexJUDU7nXryPMeta?.alias || [],
    redirect: indexJUDU7nXryPMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: propertyACIpkLrBkEMeta?.name ?? "properties-property-create",
    path: propertyACIpkLrBkEMeta?.path ?? "/property/create",
    redirect: {"name":"properties-property-create-address"},
    children: [
  {
    name: addresskJHU6monYvMeta?.name ?? "properties-property-create-address",
    path: addresskJHU6monYvMeta?.path ?? "address",
    meta: addresskJHU6monYvMeta || {},
    alias: addresskJHU6monYvMeta?.alias || [],
    redirect: addresskJHU6monYvMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutG8TFU0cAyuMeta?.name ?? "properties-property-create-about",
    path: aboutG8TFU0cAyuMeta?.path ?? "about",
    meta: aboutG8TFU0cAyuMeta || {},
    alias: aboutG8TFU0cAyuMeta?.alias || [],
    redirect: aboutG8TFU0cAyuMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listingqEihnCbNjxMeta?.name ?? "properties-property-create-listing",
    path: listingqEihnCbNjxMeta?.path ?? "listing",
    meta: listingqEihnCbNjxMeta || {},
    alias: listingqEihnCbNjxMeta?.alias || [],
    redirect: listingqEihnCbNjxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediamPFrfLxN6KMeta?.name ?? "properties-property-create-media",
    path: mediamPFrfLxN6KMeta?.path ?? "media",
    meta: mediamPFrfLxN6KMeta || {},
    alias: mediamPFrfLxN6KMeta?.alias || [],
    redirect: mediamPFrfLxN6KMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
],
    meta: propertyACIpkLrBkEMeta || {},
    alias: propertyACIpkLrBkEMeta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property.vue").then(m => m.default || m)
  },
  {
    name: editR4hWBNoU2SMeta?.name ?? "properties-property-edit",
    path: editR4hWBNoU2SMeta?.path ?? "/property/:uuid/edit",
    redirect: {"name":"properties-property-edit-address"},
    children: [
  {
    name: addresskJHU6monYvMeta?.name ?? "properties-property-edit-address",
    path: addresskJHU6monYvMeta?.path ?? "address",
    meta: addresskJHU6monYvMeta || {},
    alias: addresskJHU6monYvMeta?.alias || [],
    redirect: addresskJHU6monYvMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutG8TFU0cAyuMeta?.name ?? "properties-property-edit-about",
    path: aboutG8TFU0cAyuMeta?.path ?? "about",
    meta: aboutG8TFU0cAyuMeta || {},
    alias: aboutG8TFU0cAyuMeta?.alias || [],
    redirect: aboutG8TFU0cAyuMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listingqEihnCbNjxMeta?.name ?? "properties-property-edit-listing",
    path: listingqEihnCbNjxMeta?.path ?? "listing",
    meta: listingqEihnCbNjxMeta || {},
    alias: listingqEihnCbNjxMeta?.alias || [],
    redirect: listingqEihnCbNjxMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediamPFrfLxN6KMeta?.name ?? "properties-property-edit-media",
    path: mediamPFrfLxN6KMeta?.path ?? "media",
    meta: mediamPFrfLxN6KMeta || {},
    alias: mediamPFrfLxN6KMeta?.alias || [],
    redirect: mediamPFrfLxN6KMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
],
    meta: editR4hWBNoU2SMeta || {},
    alias: editR4hWBNoU2SMeta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/properties/pages/property/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: partnershipwKCkgEhelaMeta?.name ?? "resources-partnership",
    path: partnershipwKCkgEhelaMeta?.path ?? "/resources/partnership",
    meta: partnershipwKCkgEhelaMeta || {},
    alias: partnershipwKCkgEhelaMeta?.alias || [],
    redirect: partnershipwKCkgEhelaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/resources/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: marketing_45materialE4Jf3qiQ7vMeta?.name ?? "resources-marketing-material",
    path: marketing_45materialE4Jf3qiQ7vMeta?.path ?? "/resources/marketing-material",
    meta: marketing_45materialE4Jf3qiQ7vMeta || {},
    alias: marketing_45materialE4Jf3qiQ7vMeta?.alias || [],
    redirect: marketing_45materialE4Jf3qiQ7vMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/resources/pages/marketing-material.vue").then(m => m.default || m)
  },
  {
    name: indexu8JwDpJf9iMeta?.name ?? "tenancies",
    path: indexu8JwDpJf9iMeta?.path ?? "/tenancies",
    meta: indexu8JwDpJf9iMeta || {},
    alias: indexu8JwDpJf9iMeta?.alias || [],
    redirect: indexu8JwDpJf9iMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/tenancies/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexVjSOmGm7CEMeta?.name ?? "utilities",
    path: indexVjSOmGm7CEMeta?.path ?? "/utilities",
    meta: indexVjSOmGm7CEMeta || {},
    alias: indexVjSOmGm7CEMeta?.alias || [],
    redirect: indexVjSOmGm7CEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/index.vue").then(m => m.default || m)
  },
  {
    name: orderMjKqtjcrY3Meta?.name ?? "utilities-order",
    path: orderMjKqtjcrY3Meta?.path ?? "/utilities/order",
    redirect: {"name":"utilities-order-details"},
    children: [
  {
    name: detailsb1nL6kkY1OMeta?.name ?? "utilities-order-details",
    path: detailsb1nL6kkY1OMeta?.path ?? "details",
    meta: detailsb1nL6kkY1OMeta || {},
    alias: detailsb1nL6kkY1OMeta?.alias || [],
    redirect: detailsb1nL6kkY1OMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantskcH6fm7HuDMeta?.name ?? "utilities-order-tenants",
    path: tenantskcH6fm7HuDMeta?.path ?? "tenants",
    meta: tenantskcH6fm7HuDMeta || {},
    alias: tenantskcH6fm7HuDMeta?.alias || [],
    redirect: tenantskcH6fm7HuDMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryiy1QMg2hOZMeta?.name ?? "utilities-order-summary",
    path: summaryiy1QMg2hOZMeta?.path ?? "summary",
    meta: summaryiy1QMg2hOZMeta || {},
    alias: summaryiy1QMg2hOZMeta?.alias || [],
    redirect: summaryiy1QMg2hOZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: preview0ydTdfm08dMeta?.name ?? "utilities-order-preview",
    path: preview0ydTdfm08dMeta?.path ?? "preview",
    meta: preview0ydTdfm08dMeta || {},
    alias: preview0ydTdfm08dMeta?.alias || [],
    redirect: preview0ydTdfm08dMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  }
],
    meta: orderMjKqtjcrY3Meta || {},
    alias: orderMjKqtjcrY3Meta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order.vue").then(m => m.default || m)
  },
  {
    name: indexu0HvU7NplNMeta?.name ?? "utilities-order-edit",
    path: indexu0HvU7NplNMeta?.path ?? "/utilities/order/:uuid",
    redirect: {"name":"utilities-order-edit-details"},
    children: [
  {
    name: detailsb1nL6kkY1OMeta?.name ?? "utilities-order-edit-details",
    path: detailsb1nL6kkY1OMeta?.path ?? "details",
    meta: detailsb1nL6kkY1OMeta || {},
    alias: detailsb1nL6kkY1OMeta?.alias || [],
    redirect: detailsb1nL6kkY1OMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantskcH6fm7HuDMeta?.name ?? "utilities-order-edit-tenants",
    path: tenantskcH6fm7HuDMeta?.path ?? "tenants",
    meta: tenantskcH6fm7HuDMeta || {},
    alias: tenantskcH6fm7HuDMeta?.alias || [],
    redirect: tenantskcH6fm7HuDMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryiy1QMg2hOZMeta?.name ?? "utilities-order-edit-summary",
    path: summaryiy1QMg2hOZMeta?.path ?? "summary",
    meta: summaryiy1QMg2hOZMeta || {},
    alias: summaryiy1QMg2hOZMeta?.alias || [],
    redirect: summaryiy1QMg2hOZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: preview0ydTdfm08dMeta?.name ?? "utilities-order-edit-preview",
    path: preview0ydTdfm08dMeta?.path ?? "preview",
    meta: preview0ydTdfm08dMeta || {},
    alias: preview0ydTdfm08dMeta?.alias || [],
    redirect: preview0ydTdfm08dMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  },
  {
    name: confirmationZaZpVnZt6NMeta?.name ?? "utilities-order-edit-confirmation",
    path: confirmationZaZpVnZt6NMeta?.path ?? "confirmation",
    meta: confirmationZaZpVnZt6NMeta || {},
    alias: confirmationZaZpVnZt6NMeta?.alias || [],
    redirect: confirmationZaZpVnZt6NMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/confirmation.vue").then(m => m.default || m)
  }
],
    meta: indexu0HvU7NplNMeta || {},
    alias: indexu0HvU7NplNMeta?.alias || [],
    component: () => import("/var/lib/jenkins/workspace/Vapor/agents_deploy_stage/agents/modules/utilities/pages/order/[uuid]/index.vue").then(m => m.default || m)
  }
]