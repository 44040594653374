<script setup lang="ts">
import { Input } from 'flowbite-vue';
import { useSlots, useAttrs, onMounted } from 'vue';
import { useVModel, unrefElement } from '@vueuse/core';
import type { InputType, InputSize } from './types';

defineOptions({
  inheritAttrs: false,
});

const slots = useSlots();
const attrs = useAttrs();

interface UhInputProps {
  id?: string;
  name: string;
  label?: string;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  modelValue: string | number;
  size?: InputSize;
}

const props = withDefaults(defineProps<UhInputProps>(), {
  id: '',
  type: 'text',
  disabled: false,
  required: false,
  placeholder: '',
  invalid: false,
  errorMessage: '',
  label: '',
  size: 'md',
  modelValue: '',
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const inputWrapper = ref<HTMLElement | null>(null);

onMounted(() => {
  const el = unrefElement(inputWrapper) as HTMLElement;
  el.removeAttribute('name');
});
</script>

<template>
  <Input
    :id="props.id"
    ref="inputWrapper"
    v-bind="attrs"
    v-model="model"
    :size="props.size"
    :required="props.required"
    :type="props.type"
    :name="props.name"
    :disabled="props.disabled"
    :placeholder="props.placeholder"
    :label="props.label"
    :class="{
      '!focus:border-blue-300 !focus:ring-4 min-h-[42px] border-gray-200 bg-white py-0 !text-base hover:border-gray-900 active:border-gray-900 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:border-gray-300': true,
      'border-red-600': props.invalid,
    }"
  >
    <template v-if="slots.prefix" #prefix>
      <slot name="prefix"></slot>
    </template>

    <template v-if="slots.suffix" #suffix>
      <slot name="suffix"></slot>
    </template>

    <template
      v-if="slots.helper || (props.invalid && props.errorMessage !== '')"
      #helper
    >
      <span v-if="props.invalid" class="mt-2 text-red-600">
        {{ props.errorMessage }}
      </span>
      <slot name="helper"></slot>
    </template>
  </Input>
</template>
