import { config, library } from '@fortawesome/fontawesome-svg-core';
// eslint-disable-next-line import/named
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Free solid icons
import {
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBath as fasBath,
  faBed as fasBed,
  faBell,
  faBuilding,
  faCalendar,
  faChartColumn,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCloudArrowUp,
  faCopy,
  faEllipsisH,
  faEllipsisVertical as fasEllipsisVertical,
  faEnvelope,
  faEquals as fasEquals,
  faExclamation,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileArrowDown,
  faFilePdf as fasFilePdf,
  faFilter,
  faGear,
  faHouse,
  faHouseSignal as fasHouseSignal,
  faInfo,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMask,
  faMoon,
  faPause,
  faPenToSquare,
  faPhone,
  faPlay as fasPlay,
  faPlus,
  faRocket,
  faRotateLeft,
  faRotateRight,
  faSpinner,
  faStar,
  faSterlingSign as fasSterlingSign,
  faTableList,
  faTrashCan,
  faTriangleExclamation,
  faUpload as fasUpload,
  faUser,
  faUserGroup,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

// https://fontawesome.com/docs/web/use-with/vue/add-icons#same-icon-different-styles
// Pro regular icons
import {
  faArrowDown as farArrowDown,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowsRotate as farArrowsRotate,
  faArrowUp as farArrowUp,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBan as farBan,
  faBell as farBell,
  faBolt as farBolt,
  faBook as farBook,
  faBuilding as farBuilding,
  faCalendars as farCalendars,
  faChartColumn as farChartColumn,
  faChartMixed as farChartMixed,
  faChartUser as farChartUser,
  faChevronDown as farChevronDown,
  faCircle as farCircle,
  faCircleCheck as farCircleCheck,
  faClipboard as farClipboard,
  faDash as farDash,
  faDownload as farDownload,
  faEnvelope as farEnvelope,
  faExclamationCircle as farExclamationCircle,
  faEye as farEye,
  faFileArrowDown as farFileArrowDown,
  faFileArrowUp as farFileArrowUp,
  faFileCertificate as farFileCertificate,
  faFileCircleCheck as farFileCircleCheck,
  faFileCircleQuestion as farFileCircleQuestion,
  faFileCircleXmark as farFileCircleXmark,
  faFileContract as farFileContract,
  faFileImport as farFileImport,
  faFilePlus as farFilePlus,
  faFiles as farFiles,
  faFilter as farFilter,
  faGaugeMax as farGaugeMax,
  faGear as farGear,
  faHouse as farHouse,
  faHouseBuilding as farHouseBuilding,
  faHouseCircleCheck as farHouseCircleCheck,
  faHousePersonReturn as farHousePersonReturn,
  faInbox as farInbox,
  faLightbulb as farLightbulb,
  faLockKeyhole as farLockKeyhole,
  faLocationPlus as farLocationPlus,
  faMagnifyingGlass as farMagnifyingGlass,
  faPaperPlane as farPaperPlane,
  faPlus as farPlus,
  faReceipt as farReceipt,
  faRocket as farRocket,
  faSlidersSimple as farSlidersSimple,
  faSparkles as farSparkles,
  faSunBright as farSunBright,
  faTableList as farTableList,
  faTrash as farTrash,
  faUnlockKeyhole as farUnlockKeyhole,
  faUpload as farUpload,
  faUser as farUser,
  faUsers as farUsers,
  faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faChartMixed as fasChartMixed,
  faChartPieSimple,
  faClone,
  faMessageSms,
  faLocationPlus as fasLocationPlus,
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt worry about the CSS
library.add(
  // Solid
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCalendar,
  faChartColumn,
  faChartPie,
  faChartPieSimple,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faClone,
  faCloudArrowUp,
  faCopy,
  faEllipsisH,
  faEnvelope,
  faExclamation,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileArrowDown,
  faFilter,
  faGear,
  faHouse,
  faInfo,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMask,
  faMessageSms,
  faMoon,
  faPause,
  faPenToSquare,
  faPhone,
  faPlus,
  faRocket,
  faRotateLeft,
  faRotateRight,
  faSpinner,
  faStar,
  faTableList,
  faTrashCan,
  faTriangleExclamation,
  faUser,
  faUserGroup,
  faXmark,
  // Regular
  farArrowDown,
  farArrowUp,
  farArrowRightFromBracket,
  farArrowsRotate,
  farArrowUpRightFromSquare,
  farBan,
  farBell,
  farBolt,
  farBook,
  farBuilding,
  farCalendars,
  farChartColumn,
  farChartMixed,
  farChartUser,
  farChevronDown,
  farCircle,
  farCircleCheck,
  farClipboard,
  farDash,
  farDownload,
  farEnvelope,
  farExclamationCircle,
  farEye,
  farFileArrowDown,
  farFileArrowUp,
  farFileCertificate,
  farFileCircleCheck,
  farFileCircleQuestion,
  farFileCircleXmark,
  farFileContract,
  farFileImport,
  farFilePlus,
  farFiles,
  farFilter,
  farInbox,
  farGaugeMax,
  farGear,
  farHouse,
  farHouseBuilding,
  farHouseCircleCheck,
  farHousePersonReturn,
  farLightbulb,
  farLockKeyhole,
  farLocationPlus,
  farMagnifyingGlass,
  farPaperPlane,
  farPlus,
  farReceipt,
  farRocket,
  farSlidersSimple,
  farSparkles,
  farSunBright,
  farTableList,
  farTrash,
  farUpload,
  farUnlockKeyhole,
  farUser,
  farUsers,
  farXmark,
  // Solid
  fasBath,
  fasBed,
  fasEllipsisVertical,
  fasHouseSignal,
  fasSterlingSign,
  fasUpload,
  fasPlay,
  fasFilePdf,
  fasChartMixed,
  fasEquals,
  fasLocationPlus,
);

config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
});
