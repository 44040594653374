import { useMainStore } from '~/stores/mainStore';

export const useHelpers = () => {
  function updateRouteQueryParams(query): void {
    const router = useRouter();
    const { scrollToTop } = useMainStore();

    scrollToTop();

    router.push({
      path: router.currentRoute.value.path,
      query,
    });
  }

  const units = [
    'bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  function formatBytes(x: any): string {
    let l = 0;
    let n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  function formatPrice(value: number): String {
    const strValue = String(value);
    const nums = strValue.split('.');

    // e.g: 99 or 0.99
    if (nums.length === 1 || nums[1].length > 1) return strValue;

    // e.g: 9.9
    return `${strValue}0`;
  }

  function cdnAsset(value: string): string {
    const runtimeConfig = useRuntimeConfig();
    return `${runtimeConfig.public.cdnUrl}/${value}`;
  }

  return {
    updateRouteQueryParams,
    formatBytes,
    formatPrice,
    cdnAsset,
  };
};
