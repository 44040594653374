import { type MaybeRefOrGetter, ref } from 'vue';
import { toRef } from '@vueuse/core';
import { defu } from 'defu';
import { QueryParams } from './../../types/index';
import { useQueryBuilder } from './useQueryBuilder';
import { useApiFetch } from './useApiFetch';
import { useRoute } from '#app';

export async function useTableBlobFetch<T>(
  url: string,
  query: MaybeRefOrGetter<QueryParams>
) {
  const _query = toRef(query);

  const route = useRoute();

  const { parse, build } = useQueryBuilder();

  // Merge default query params with route query params
  _query.value = defu(parse(route.query), _query.value);

  const fetchParams = ref(build(_query));

  const { data, pending, error, refresh } = await useApiFetch<T>(url, {
    query: fetchParams,
    refetch: true,
  });

  return { data, pending, error, refresh };
}
