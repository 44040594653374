import { shallowRef } from 'vue';
import { ToastType } from 'flowbite-vue/dist/components/Toast/types';
import { ToastItem } from 'flowbite-vue/dist/components/Toast/components/ToastProvider/types';
import UhToast from '~/components/notifications/uhToasts/UhToast.vue';
import { useToasterStore } from '~/components/notifications/uhToasts/uhToastProvider/store';

export type UseToaster = {
  add: (type: ToastType, message: string, time?: number) => string;
  addCustom: (toast: ToastItem) => string;
  remove: (id: string) => boolean;
  pop: () => string;
};

export const useToaster = (): UseToaster => {
  const store = useToasterStore();

  const addCustom = (toast: ToastItem): string => {
    return store.addToast(toast);
  };

  const add = (type: ToastType, message: string, time?: number) => {
    return store.addToast({
      type,
      text: message,
      time: time || 10 * 1000,
      component: shallowRef(UhToast),
      componentProps: {
        closable: true,
        type,
        divide: true,
      },
    });
  };

  const remove = (id: string): boolean => {
    return store.removeToast(id);
  };

  const pop = (): string => {
    return store.popToast();
  };

  return {
    addCustom,
    add,
    remove,
    pop,
  };
};
